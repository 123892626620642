import sessionStorage from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import { authReducers } from '@zillow/zhl-redux-commons';

export default persistReducer(
    {
        version: 1,
        key: 'auth',
        keyPrefix: 'reduxPersist:',
        storage: sessionStorage,
        // We want to use the reduced state as the source of truth over the session storage
        // This is to take into account expired refresh tokens being refreshed via the hash params
        stateReconciler: (inbound, original, reduced) => ({
            ...original,
            ...inbound,
            ...reduced,
        }),
    },
    authReducers.default
);
