import { useState, useEffect, useRef, cloneElement } from 'react';
import { mediaMixin, Anchor, VisuallyHiddenMixin, token, spaceMixin } from '@zillow/constellation';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Header, HeaderLOP, Footer, ABAD, NAVIGATION_ITEMS } from '@zillow/zhl-wordpress-commons';
import LogoutBanner from '../Banners/LogoutBanner.contextual';
import SessionNearingExpiry from '../Modals/SessionNearingExpiry.contextual';

const SkipLink = styled(Anchor)`
    &:focus {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        padding: ${spaceMixin('sm')};
        background: ${token('colors.backgroundWhite')};
    }
    &:not(:focus) {
        ${VisuallyHiddenMixin}
    }
`;

const StyledContent = styled.main`
    ${({ $hidden }) =>
        $hidden &&
        css`
            visibility: hidden;
        `}
    height: 100%;
`;
const StyledPage = styled.div`
    position: relative;
    ${Header.SubNav} {
        border-width: 0;
    }
    ${mediaMixin(
        'lg',
        css`
            ${Header.SubNav} {
                border-width: 1px;
            }
        `
    )};
`;
const StyledContentContainer = styled.div`
    position: relative;
`;

const MAIN_CONTENT_ID = 'main-content';

const Page = ({ children, ...props }) => {
    const {
        renderLogoutBanner,
        header,
        includeFooter,
        shouldRenderLOPHeader,
        primaryNav,
        history,
        setSessionTimeouts,
    } = props;

    const [mobileOpen, setMobileOpen] = useState(false);
    useEffect(() => {
        setSessionTimeouts();
        return history.listen(() => {
            setMobileOpen(false);
        });
    }, [history, setSessionTimeouts]);

    const skipLinkRef = useRef();
    const navKeyBasedHeader = shouldRenderLOPHeader ? <HeaderLOP /> : header;

    return (
        <>
            <SkipLink
                href={`#${MAIN_CONTENT_ID}`}
                onClick={event => {
                    if (skipLinkRef.current?.focus) {
                        skipLinkRef.current.focus();
                        skipLinkRef.current.scrollIntoView?.();
                        event.preventDefault();
                    }
                    setMobileOpen(false);
                }}>
                Skip to main content
            </SkipLink>
            <ABAD>
                <StyledPage>
                    {renderLogoutBanner && <LogoutBanner />}
                    <SessionNearingExpiry />
                    {navKeyBasedHeader &&
                        cloneElement(navKeyBasedHeader, {
                            onHamburgerClick: () => setMobileOpen(!mobileOpen),
                            mobileOpen,
                            primaryNav,
                        })}
                    <StyledContentContainer>
                        <a ref={skipLinkRef} id={MAIN_CONTENT_ID} tabIndex="-1" />
                        <StyledContent $hidden={mobileOpen}>{cloneElement(children, props)}</StyledContent>
                        {includeFooter && <Footer $hidden={mobileOpen} />}
                    </StyledContentContainer>
                </StyledPage>
            </ABAD>
        </>
    );
};

Page.propTypes = {
    isPassportSource: PropTypes.bool,
    shouldShowAdDisclaimer: PropTypes.bool,
    children: PropTypes.node,
    header: PropTypes.node,
    history: PropTypes.shape({
        listen: PropTypes.func.isRequired,
    }),
    shouldRenderLOPHeader: PropTypes.bool,
    renderLogoutBanner: PropTypes.bool,
    setSessionTimeouts: PropTypes.func.isRequired,
    includeHeader: PropTypes.bool,
    includeFooter: PropTypes.bool,
    primaryNav: PropTypes.shape({
        primary: PropTypes.array,
        secondary: PropTypes.array,
    }),
};

Page.defaultProps = {
    header: <Header />,
    history: {
        listen: () => () => {},
    },
    shouldRenderLOPHeader: false,
    renderLogoutBanner: false,
    includeHeader: true,
    includeFooter: true,
    primaryNav: NAVIGATION_ITEMS.www,
};

export default Page;
