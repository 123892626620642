import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Paragraph, Anchor } from '@zillow/constellation';
import { createHashHistory } from 'history';
import { Router, withRouter } from 'react-router-dom';
import { gaCommand } from '@zillow/universal-analytics';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { constants } from '@zillow/zhl-yup';
import { PersistorContext } from '@zillow/zhl-wordpress-commons';
import { queryParamsReducers } from '@zillow/zhl-redux-commons';
import ZHLTheme from '../ZHLTheme/ZHLTheme';
import Page from '../Page/Page.contextual';
import ErrorPage from '../ErrorPage/ErrorPage';
import { store, getPersistor } from '../../redux';
import PageTitleProvider from './PageTitleProvider';
const { ZHL_PHONE_NUMBER_TEL, ZHL_PHONE_NUMBER } = constants;

const persistor = getPersistor(store);
const PagelessApp = ({ children }) => {
    const wrappedChildren = <PersistorContext.Provider value={persistor}>{children}</PersistorContext.Provider>;
    return (
        <ZHLTheme>
            <Provider store={store}>
                {typeof window === 'undefined' ? (
                    wrappedChildren
                ) : (
                    <PersistGate persistor={persistor}>{wrappedChildren}</PersistGate>
                )}
            </Provider>
        </ZHLTheme>
    );
};
PagelessApp.propTypes = {
    children: PropTypes.node,
    fallback: PropTypes.elementType,
};

const App = ({ history, children, location, renderLogoutBanner, ...otherProps }) => {
    useEffect(() => {
        const onClearParameters = history
            ? () => {
                  history.replace({ pathname: window.location.hash.substr(1) });
              }
            : () => {};
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        window.ga(() => {
            queryParamsReducers.clearParameters(onClearParameters);
        });
        if (window.ga.isGaBackup) {
            queryParamsReducers.clearParameters(onClearParameters);
        }
        //Initialize app on mount
        // eslint-disable-next-line
    }, []);
    return (
        <PagelessApp>
            <Page history={history} location={location} renderLogoutBanner={renderLogoutBanner} {...otherProps}>
                {children}
            </Page>
        </PagelessApp>
    );
};
App.propTypes = {
    children: PropTypes.node,
    location: PropTypes.object,
    history: PropTypes.object,
    renderLogoutBanner: PropTypes.bool,
};
App.defaultProps = {
    renderLogoutBanner: false,
};
const AppWithSentry = ({ fallback, ...props }) => (
    <SentryErrorBoundary fallback={<App>{fallback}</App>}>
        <App {...props} />
    </SentryErrorBoundary>
);
AppWithSentry.propTypes = {
    fallback: PropTypes.node,
};
AppWithSentry.defaultProps = {
    fallback: (
        <ErrorPage
            includeSubheading={false}
            errorDetails={
                <Paragraph>
                    To get things back on track, try using a different web browser or simply give one of our loan
                    officers a call at <Anchor href={ZHL_PHONE_NUMBER_TEL}>{ZHL_PHONE_NUMBER}</Anchor>.
                </Paragraph>
            }
            homePageText={null}
        />
    ),
};
const AppWithRouter = withRouter(AppWithSentry);
export const AppWithSubroutes = ({ children, staticRender, ...otherProps }) => {
    const [history, setHistory] = useState(null);
    useEffect(() => {
        const localHistory = createHashHistory();
        setHistory(localHistory);
        const unlisten = localHistory.listen(location => {
            gaCommand('set', 'page', location.pathname);
            gaCommand('send', 'pageview');
            window.scrollTo(0, 0);
        });
        return unlisten;
    }, []);
    return history ? (
        <PageTitleProvider>
            <Router history={history}>
                <AppWithRouter {...otherProps}>{children}</AppWithRouter>
            </Router>
        </PageTitleProvider>
    ) : staticRender ? (
        <App>{staticRender}</App>
    ) : null;
};
AppWithSubroutes.propTypes = {
    children: PropTypes.node,
    staticRender: PropTypes.node,
};

export default AppWithSentry;
