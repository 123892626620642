import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Button, Heading, spaceMixin, mediaMixin, token } from '@zillow/constellation';
import { ReactComponent as StairsBackgroundSVG } from './StairsBackground.svg';

/**
 * Source copied from https://gitlab.zgtools.net/zillow-offers/common/node/zrs-zillow-offers/-/blob/master/src/components/ErrorPage/ErrorPage.jsx
 *
 * zrs-zo team API is a large bundle and a one off usage of the component seems like the wise choice. In addition, they are on constellation 8
 * and this code required some massaging to work within ZHL (we don't use their page wrapper, for instance)
 */
const StairsBackground = styled(StairsBackgroundSVG)`
    display: none;

    ${mediaMixin(
        'lg',
        css`
            display: block;
            position: absolute;
            width: 100%;
            z-index: 1;
        `
    )};
`;

const ContentWrapper = styled.div`
    max-width: ${token('sizing.contentWidth')};
    margin: 0 auto;
    padding-left: ${spaceMixin('xs')};
    padding-right: ${spaceMixin('xs')};
    padding-top: ${spaceMixin('xl')};
    position: relative;
    text-align: center;
    z-index: 2;
    ${mediaMixin(
        'lg',
        css`
            padding-left: ${spaceMixin('lg')};
            text-align: left;
        `
    )};
`;
const ContentWidthControl = styled.div`
    ${mediaMixin(
        'lg',
        css`
            max-width: 60%;
        `
    )}
`;

const DESKTOP_HEIGHT = 600;

const StyledErrorPage = styled.div`
    position: relative;
    background-color: ${token('colors.blue200')};
    ${mediaMixin(
        'md',
        css`
            height: ${DESKTOP_HEIGHT + 200}px;
        `
    )};
`;

const ErrorPage = ({ errorCode, errorDetails, errorMessage, homePageText, homePageUrl, includeSubheading }) => (
    <StyledErrorPage>
        <StairsBackground />
        <ContentWrapper>
            <ContentWidthControl>
                <Heading level="1" fontFamily="serif">
                    Something looks a little off, but we can help!
                </Heading>
                {includeSubheading && (
                    <Heading level="4">
                        Error {errorCode} - {errorMessage}
                    </Heading>
                )}
                {errorDetails}
                {homePageText && (
                    <Button buttonType="primary" marginTop="lg" as="a" href={homePageUrl}>
                        {homePageText}
                    </Button>
                )}
            </ContentWidthControl>
        </ContentWrapper>
    </StyledErrorPage>
);

ErrorPage.propTypes = {
    includeSubheading: PropTypes.bool,
    errorCode: PropTypes.number,
    errorMessage: PropTypes.string,
    errorDetails: PropTypes.node,
    homePageText: PropTypes.string,
    homePageUrl: PropTypes.string,
};

ErrorPage.defaultProps = {
    includeSubheading: true,
    errorCode: 404,
    errorDetails: null,
    errorMessage: 'page not found',
    homePageText: 'Return to Zillow Home Loans',
    homePageUrl: 'https://www.zillowhomeloans.com',
};

export default ErrorPage;
