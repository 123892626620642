import storage from 'redux-persist/lib/storage';
import { trackEvent } from '@zillow/universal-analytics';
import { persistReducer, createTransform } from 'redux-persist';
import omit from 'lodash/omit';
import { API, createActionObject, preQualifyReducers, preQualifySelectors } from '@zillow/zhl-redux-commons';
import { ROUTES_LOOKUP, PATH_FOR_ROUTE } from '../selectors/preQualify/preQualifyRouting';

const { preQualifyUnderwritingApprovedSelector, preQualifyHasSubmittedSelector, shouldOffRampSelector } =
    preQualifySelectors;

const {
    submitPreQualifyForm,
    submitLead,
    submitIncome,
    submitAssets,
    handlePreQualifySubmission,
    grantSoftCreditConsentFromPreQualify,
    updateBorrowerTaxpayerIDFromPreQualify,
    consentToESignatures,
    consentToTCPA,
} = preQualifyReducers;

const additionalWorkForRoute = currentRoute => async (dispatch, getState) => {
    switch (currentRoute) {
        case ROUTES_LOOKUP.INCOME: {
            return dispatch(submitIncome());
        }
        case ROUTES_LOOKUP.ASSETS: {
            return dispatch(submitAssets());
        }
        case ROUTES_LOOKUP.CREDIT: {
            trackEvent({
                category: 'Mortgages',
                action: 'SSN submission',
            });

            return Promise.all([
                dispatch(grantSoftCreditConsentFromPreQualify()),
                dispatch(updateBorrowerTaxpayerIDFromPreQualify()),
            ]);
        }
        case ROUTES_LOOKUP.ASSISTANCE_PROGRAMS_WITHOUT_LOGIN: {
            dispatch(consentToESignatures());
            dispatch(consentToTCPA());
            const state = getState();
            const shouldOffRamp = shouldOffRampSelector(state);
            if (shouldOffRamp) {
                return;
            }
            // only call submitLead on happy path
            return dispatch(submitLead());
        }
    }
};

export const handleSubmit = (currentRoute, formData) => async dispatch => {
    dispatch(submitPreQualifyForm(formData));

    await dispatch(additionalWorkForRoute(currentRoute));
};

const createNestedDenyListTransforms = denyPathsForKey =>
    Object.entries(denyPathsForKey).map(([whitelist, denyPaths]) =>
        createTransform(
            inboundState => omit(inboundState, denyPaths),
            outboundState => outboundState,
            {
                whitelist: [whitelist],
            }
        )
    );

export default persistReducer(
    {
        version: 1,
        key: 'preQualify',
        keyPrefix: 'reduxPersist:',
        storage,
        transforms: createNestedDenyListTransforms({ fields: ['socialSecurityNumber'] }),
    },
    preQualifyReducers.default
);

const API_SWAP_PARTICIPANT = createActionObject('API_SWAP_PARTICIPANT');
const apiSwapParticipant = () =>
    API['loan-file'].POST.thunk(
        {
            action: 'v1/participant/actions/swap-participant',
            authenticated: true,
        },
        API_SWAP_PARTICIPANT
    );

export const handleSubmission =
    (shouldSwapParticipant = false) =>
    async (dispatch, getState) => {
        const hasSubmitted = preQualifyHasSubmittedSelector(getState());
        // if Beth already submitted, prevent re-submission by sending her to the entry route
        if (hasSubmitted) {
            return PATH_FOR_ROUTE[ROUTES_LOOKUP.ENTRY];
        }
        try {
            if (shouldSwapParticipant) {
                await dispatch(apiSwapParticipant());
            }
            await dispatch(handlePreQualifySubmission());
            const isUnderwritingApproved = preQualifyUnderwritingApprovedSelector(getState());
            return PATH_FOR_ROUTE[isUnderwritingApproved ? ROUTES_LOOKUP.SUCCESS : ROUTES_LOOKUP.OFF_RAMP_WITH_LOGIN];
        } catch (e) {
            return PATH_FOR_ROUTE[ROUTES_LOOKUP.OFF_RAMP_WITH_LOGIN];
        }
    };
