import PropTypes from 'prop-types';
import { Heading, ModalDialog, Button, Paragraph, ButtonGroup, TextButton } from '@zillow/constellation';

const SessionNearingExpiry = ({
    showSessionNearingExpiryNotification,
    initiateLoginInNewTab,
    logout,
    setShowSessionNearingExpiryNotification,
}) => {
    const onModalClose = () => {
        setShowSessionNearingExpiryNotification(false);
    };
    const onSignoutClick = () => {
        onModalClose();
        logout();
    };
    const onSigninClick = () => {
        onModalClose();
        initiateLoginInNewTab();
    };
    return (
        <ModalDialog
            wide
            isOpen={showSessionNearingExpiryNotification}
            onClose={onModalClose}
            header={<Heading level="5">Your session is about to expire</Heading>}
            body={
                <Paragraph>
                    For your security, when your account is inactive you'll be signed out of Zillow Home Loans and any
                    unsaved information will be deleted. To securely continue this session and keep your info, you’ll be
                    asked to re-enter your password.
                </Paragraph>
            }
            footer={
                <ButtonGroup aria-label="Session actions">
                    <TextButton onClick={onSignoutClick}>Sign out</TextButton>
                    <Button buttonType="primary" onClick={onSigninClick}>
                        Continue session
                    </Button>
                </ButtonGroup>
            }
        />
    );
};

SessionNearingExpiry.propTypes = {
    showSessionNearingExpiryNotification: PropTypes.bool,
    initiateLoginInNewTab: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    setShowSessionNearingExpiryNotification: PropTypes.func.isRequired,
};

export default SessionNearingExpiry;
