import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { queryParamsReducers, queryParamsSelectors, authReducers } from '@zillow/zhl-redux-commons';
import { primaryNavSelector, shouldRenderLOPHeader } from '../../redux/selectors/navigation';
import Page from './Page';

const ConnectedPage = connect(
    state => ({
        isPassportSource: queryParamsSelectors.isPassportSourceSelector(state),
        shouldRenderLOPHeader: shouldRenderLOPHeader(state),
        primaryNav: primaryNavSelector(state),
    }),
    dispatch =>
        bindActionCreators(
            {
                deleteParam: queryParamsReducers.deleteParam,
                setSessionTimeouts: authReducers.sessionTimeoutsHandler.setSessionTimeouts,
            },
            dispatch
        )
)(Page);
export default ConnectedPage;
