import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authSelectors, authReducers } from '@zillow/zhl-redux-commons';
import SessionNearingExpiry from './SessionNearingExpiry';

export default connect(
    state => ({
        showSessionNearingExpiryNotification: authSelectors.showSessionNearingExpiryNotificationSelector(state),
    }),
    dispatch =>
        bindActionCreators(
            {
                initiateLoginInNewTab: authReducers.initiateLoginInNewTab,
                logout: authReducers.logout,
                setShowSessionNearingExpiryNotification: authReducers.setShowSessionNearingExpiryNotification,
            },
            dispatch
        )
)(SessionNearingExpiry);
