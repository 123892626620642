import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
    mortgageCalculatorReducers,
    affordabilityCalculatorReducers,
    refinanceCalculatorReducers,
} from '@zillow/zhl-redux-commons';

export default persistCombineReducers(
    { version: 1, key: 'calculators', keyPrefix: 'reduxPersist:', storage },
    {
        mortgage: mortgageCalculatorReducers.default,
        affordability: affordabilityCalculatorReducers.default,
        refinance: refinanceCalculatorReducers.default,
    }
);
