import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authSelectors, authReducers } from '@zillow/zhl-redux-commons';
import LogoutBanner from './LogoutBanner';

export default connect(
    state => ({
        didUserInvokeLogout: authSelectors.didUserInvokeLogoutSelector(state),
        didUserSessionExpire: authSelectors.didUserSessionExpireSelector(state),
        hasUnauthenticatedRole: authSelectors.hasUnauthenticatedRoleSelector(state),
    }),
    dispatch =>
        bindActionCreators(
            {
                resetDidUserInvokeLogout: authReducers.resetDidUserInvokeLogout,
                resetDidUserSessionExpire: authReducers.resetDidUserSessionExpire,
                initiateLogin: authReducers.initiateLoginThunk,
            },
            dispatch
        )
)(LogoutBanner);
