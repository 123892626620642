import { createContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const PageTitleContext = createContext({
    setExperience: () => {},
    // eslint-disable-next-line
    setPage: page => {
        return;
    },
});
const PageTitleProvider = ({ children, defaultExperience, defaultPage }) => {
    const [experience, setExperience] = useState(defaultExperience);
    const [page, setPage] = useState(defaultPage);

    useEffect(() => {
        window.document.title = `${experience ? `${experience}: ` : ''}${page ? `${page} - ` : ''}Zillow Home Loans`;
    }, [experience, page]);
    const providerValue = useMemo(
        () => ({
            setExperience,
            setPage,
        }),
        [setExperience, setPage]
    );
    return <PageTitleContext.Provider value={providerValue}>{children}</PageTitleContext.Provider>;
};
PageTitleProvider.propTypes = {
    children: PropTypes.node.isRequired,
    defaultExperience: PropTypes.string,
    defaultPage: PropTypes.string,
};
export default PageTitleProvider;
