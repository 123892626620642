import pick from 'lodash/pick';
import { createSelector } from 'reselect';
import { preQualification } from '@zillow/zhl-yup';
import { preQualifySelectors } from '@zillow/zhl-redux-commons';

const { preQualifyFieldsSelector, preQualifyDefaultFieldsSelector } = preQualifySelectors;

const {
    journeySchema,
    propertySchema,
    borrowerInfoSchema,
    borrowerAssistanceProgramsSchema,
    borrowerDeclarationsSchema,
    incomeSchema,
    assetsSchema,
    creditSchema,
} = preQualification;

export const ROUTES_LOOKUP = {
    ENTRY: 'ENTRY',
    JOURNEY: 'JOURNEY',
    PROPERTY: 'PROPERTY',
    INFO: 'INFO',
    DECLARATIONS: 'DECLARATIONS',
    ASSISTANCE_PROGRAMS_WITHOUT_LOGIN: 'ASSISTANCE_PROGRAMS_WITHOUT_LOGIN',
    INCOME: 'INCOME',
    ASSETS: 'ASSETS',
    CREDIT: 'CREDIT',
    SUBMISSION: 'SUBMISSION',
    SUCCESS: 'SUCCESS',
    OFF_RAMP_WITH_LOGIN: 'OFF_RAMP_WITH_LOGIN',
};

export const PATH_FOR_ROUTE = {
    [ROUTES_LOOKUP.ENTRY]: '/',
    [ROUTES_LOOKUP.JOURNEY]: '/journey/',
    [ROUTES_LOOKUP.PROPERTY]: '/property/',
    [ROUTES_LOOKUP.INFO]: '/info/',
    [ROUTES_LOOKUP.DECLARATIONS]: '/declarations/',
    [ROUTES_LOOKUP.ASSISTANCE_PROGRAMS_WITHOUT_LOGIN]: '/fha/',
    [ROUTES_LOOKUP.INCOME]: '/income/',
    [ROUTES_LOOKUP.ASSETS]: '/assets/',
    [ROUTES_LOOKUP.CREDIT]: '/credit/',
    [ROUTES_LOOKUP.SUBMISSION]: '/submission/',
    [ROUTES_LOOKUP.SUCCESS]: '/success/',
    [ROUTES_LOOKUP.OFF_RAMP_WITH_LOGIN]: '/next-steps/',
};

export const VALIDATION_SCHEMA_FOR_ROUTE = {
    [ROUTES_LOOKUP.JOURNEY]: journeySchema,
    [ROUTES_LOOKUP.PROPERTY]: propertySchema,
    [ROUTES_LOOKUP.INFO]: borrowerInfoSchema,
    [ROUTES_LOOKUP.DECLARATIONS]: borrowerDeclarationsSchema,
    [ROUTES_LOOKUP.ASSISTANCE_PROGRAMS_WITHOUT_LOGIN]: borrowerAssistanceProgramsSchema,
    [ROUTES_LOOKUP.INCOME]: incomeSchema,
    [ROUTES_LOOKUP.ASSETS]: assetsSchema,
    [ROUTES_LOOKUP.CREDIT]: creditSchema,
};

export const routesToDefaultValuesSelector = createSelector(
    [preQualifyDefaultFieldsSelector, preQualifyFieldsSelector],
    (defaultFields, previouslySavedFields) =>
        Object.values(ROUTES_LOOKUP).reduce((memo, route) => {
            const validationSchema = VALIDATION_SCHEMA_FOR_ROUTE[route];
            const fields = validationSchema?.fields ?? {};
            const fieldNames = Object.keys(fields);
            const defaultValues = pick({ ...defaultFields, ...previouslySavedFields }, fieldNames);
            return { ...memo, [route]: defaultValues };
        }, {})
);
