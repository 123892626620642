/* istanbul ignore file */
import { combineReducers } from 'redux';
import createStore from '@zillow/standard-redux';
import {
    cmsReducers,
    persist,
    commonReducers,
    settingsReducers,
    processorPortalReducers,
    loanFileReducers,
    accountingPortalReducers,
    accountingSyncPortalReducers,
} from '@zillow/zhl-redux-commons';
import { queryParams } from '@zillow/zhl-wordpress-commons';
import longFlow from './reducers/longFlow';
import calculators from './reducers/calculators';
import auth from './reducers/auth';
import preQualify from './reducers/preQualify';

const baseReducers = {
    auth,
    queryParams,
    loanFile: loanFileReducers.default,
};
const ADMIN_REDUCERS = {
    ...baseReducers,
    settings: settingsReducers.default,
    processorPortal: processorPortalReducers.default,
    accountingPortal: accountingPortalReducers.default,
    accountingSyncPortal: accountingSyncPortalReducers.default,
};
const EXTERNAL_REDUCERS = {
    ...baseReducers,
    preQualify,
    longFlow,
    calculators,
    cms: cmsReducers.default,
    settings: settingsReducers.default,
};

const getStore = combinedReducers => {
    const reducer = (state, action) =>
        combinedReducers(action.type === commonReducers.CLEAR_STORE ? undefined : state, action);
    return createStore(reducer);
};

export const getPersistor = store => persist(store);
export const store = getStore(combineReducers(EXTERNAL_REDUCERS));
export const adminStore = getStore(combineReducers(ADMIN_REDUCERS));
