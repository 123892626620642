import { Component } from 'react';
import PropTypes from 'prop-types';
import { Banner, CloseButton, Text } from '@zillow/constellation';
import styled from 'styled-components';

const StyledSignin = styled.span`
    text-decoration: underline;
    &:hover {
        cursor: pointer;
    }
`;

export default class LogoutBanner extends Component {
    static propTypes = {
        didUserInvokeLogout: PropTypes.bool,
        didUserSessionExpire: PropTypes.bool,
        resetDidUserInvokeLogout: PropTypes.func.isRequired,
        resetDidUserSessionExpire: PropTypes.func.isRequired,
        initiateLogin: PropTypes.func.isRequired,
        hasUnauthenticatedRole: PropTypes.bool,
    };

    componentCleanup = () => {
        const { didUserInvokeLogout, didUserSessionExpire, resetDidUserInvokeLogout, resetDidUserSessionExpire } =
            this.props;
        if (didUserInvokeLogout) {
            resetDidUserInvokeLogout();
        } else if (didUserSessionExpire) {
            resetDidUserSessionExpire();
        }
        window.removeEventListener('beforeunload', this.componentCleanup);
    };

    componentDidMount() {
        window.addEventListener('beforeunload', this.componentCleanup);
    }

    onSignInClick = () => {
        const { initiateLogin, resetDidUserSessionExpire } = this.props;
        initiateLogin();
        resetDidUserSessionExpire();
    };

    render() {
        const {
            didUserInvokeLogout,
            didUserSessionExpire,
            resetDidUserInvokeLogout,
            resetDidUserSessionExpire,
            hasUnauthenticatedRole,
        } = this.props;

        const expiryText = hasUnauthenticatedRole
            ? 'You session has expired due to inactivity.'
            : 'Your session has expired. Please sign in again to continue.';

        return (
            <>
                {didUserInvokeLogout && (
                    <Banner
                        appearance="info"
                        body="You signed out of your Zillow Home Loans account."
                        closeButton={<CloseButton />}
                        onClose={resetDidUserInvokeLogout}
                    />
                )}
                {didUserSessionExpire && (
                    <Banner
                        appearance="warning"
                        body={
                            <>
                                <Text>{expiryText}</Text>
                                {!hasUnauthenticatedRole && (
                                    <StyledSignin onClick={this.onSignInClick}>Sign in</StyledSignin>
                                )}
                            </>
                        }
                        closeButton={<CloseButton />}
                        onClose={resetDidUserSessionExpire}
                    />
                )}
            </>
        );
    }
}
