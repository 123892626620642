import { NAVIGATION_ITEMS } from '@zillow/zhl-wordpress-commons';

export const getNavKey = () => __NAVIGATION_KEY__;
export const shouldRenderLOPHeader = () => __NAVIGATION_KEY__ === 'internal';
export const primaryNavSelector = () => {
    if (__NAVIGATION_KEY__ === 'internal') {
        return NAVIGATION_ITEMS.internal;
    }
    return NAVIGATION_ITEMS[__NAVIGATION_KEY__];
};
